import React from "react";
import { Helmet } from "react-helmet";
import Container from "../../components/chat-channel/Container";
import Layout from "../../components/chat-channel/Layout";
import RegisterSection from "../../components/chat-channel/ChannelHeader";
import RightImageWithContentFeature from "../../components/chat-channel/RightImageWithContent";
import LeftImageWithContent from "../../components/chat-channel/LeftImageWithContent";
import ArrowRightICon from "../../components/common/Icons";
import FooterForm from "../../components/common/FooterForm";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import CenterContentHome from "../../components/common/CenterContentHeader";
import { RequestForm } from "../../components/form";

const cardOne = require("../../assets/images/homepage/group1.png");
const cardTwo = require("../../assets/images/homepage/group4.png");

const topImage = require("../../assets/images/10-pages/it_upport_ind_3/itsup_ind_3_header.png");
export const icon1 = require("../../assets/images/10-pages/it_upport_ind_3/itsup_ind_3_sec_1_icon.png");
export const icon2 = require("../../assets/images/10-pages/it_upport_ind_3/itsup_ind_3_sec_2_icon.png");
export const icon3 = require("../../assets/images/10-pages/it_upport_ind_3/itsup_ind_3_sec_3_icon.png");
export const icon4 = require("../../assets/images/10-pages/it_upport_ind_3/sec_4_icon.png");

const section_one = require("../../assets/images/10-pages/it_upport_ind_3/itsup_ind_3_sec_1.png");
const section_two = require("../../assets/images/10-pages/it_upport_ind_3/itsup_ind_3_sec_2.png");
const section_three = require("../../assets/images/10-pages/it_upport_ind_3/itsup_ind_3_sec_3.png");
const section_four = require("../../assets/images/10-pages/it_upport_ind_3/sec_4_img.png");

const featureContent = [
  {
    icon: icon1,
    header: "Optimize your help desk costs.",
    content:
      "Scaling IT support via calls is expensive. Augment your existing IT staff and scale your support with our IT chatbot in few clicks.",
    link: null,
    image: section_one,
  },
  {
    icon: icon2,
    header: "Increase help desk performance.",
    content:
      "Our chatbot qualifies your incoming inquiry and only passes to a live agent if required. The tickets are classified, categorized, and assigned to the right team saving a ton of manual work.",

    link: null,
    image: section_two,
  },
  {
    icon: icon3,
    header: "Boost your CSAT",
    content:
      "Real experience is now, not later, then why should your employees wait on support. Our chatbot can deliver an exceptional experience and not just satisfaction.",
    link: null,
    image: section_three,
  },
  {
    icon: icon4,
    header: "Reduce agent attrition and boost productivity.",
    content:
      "Repetitive work can dampen agent skills and morale, leading to attrition. Workativ gives back more time to agents to focus on high-value tasks. ",
    link: null,
    image: section_four,
  },
];

export default function FeatureHomePage() {
  return (
    <>
      <TitleAndMetaTags
        title="Augment IT Helpdesk staff with IT Support Chatbot | Scale Faster and Optimize IT Helpdesk | Workativ Assistant"
        description="Reduce IT staff burnout and agent attrition with Workativ's AI chatbot. Increase helpdesk performance, IT teams productivity and improve CSAT."
        keywords={[]}
        ogImage={topImage}
        ogTitle="Augment IT Helpdesk staff with IT Support Chatbot | Scale Faster and Optimize IT Helpdesk | Workativ Assistant"
        ogDescription="Reduce IT staff burnout and agent attrition with Workativ's AI chatbot. Increase helpdesk performance, IT teams productivity and improve CSAT."
      />
      <Container>
        <Layout backgroundColor={"bg_it_support_three"} logoFor="ASSISTANT">
          <RegisterSection
            rightImage={topImage}
            backgroundColor={"bg_it_support_three"}
          >
            <RegisterSection.FormWithContent>
              <RegisterSection.MainHeader>
                Augment your IT support with our chatbot to scale faster.
              </RegisterSection.MainHeader>
              <RegisterSection.SubHeader>
                Our chatbot empowers your IT team to be more productive by
                focusing on high value and complex issues. Leave the trivial
                stuff to our chatbot.
              </RegisterSection.SubHeader>
            </RegisterSection.FormWithContent>
          </RegisterSection>{" "}
          <CenterContentHome>
            <CenterContentHome.Header>
              Our chatbot helps reduce IT staff burnout and attrition.
            </CenterContentHome.Header>
          </CenterContentHome>
          {featureContent.map((feature, index) => {
            if (index % 2 !== 0) {
              return (
                <RightImageWithContentFeature
                  image={feature.image}
                  altImage={feature.header}
                >
                  <RightImageWithContentFeature.Header>
                    <div className="icon-top-img">
                      <img src={feature.icon} alt="icon" />{" "}
                    </div>
                    <h3>{feature.header}</h3>
                  </RightImageWithContentFeature.Header>

                  <RightImageWithContentFeature.SubHeader>
                    <p>{feature.content}</p>
                  </RightImageWithContentFeature.SubHeader>
                  <RightImageWithContentFeature.Link>
                    {feature.link && (
                      <div className="card_link_landing">
                        <a href={feature.url} className="url_manipulation">
                          {feature.link}
                          <span className="arrow_svg_link">
                            <ArrowRightICon />
                          </span>
                        </a>
                      </div>
                    )}
                  </RightImageWithContentFeature.Link>
                </RightImageWithContentFeature>
              );
            }
            return (
              <LeftImageWithContent
                image={feature.image}
                altImage={feature.header}
              >
                <LeftImageWithContent.HeaderIcon>
                  <div className="icon-top-img">
                    <img src={feature.icon} alt="icon" />{" "}
                  </div>
                </LeftImageWithContent.HeaderIcon>

                <LeftImageWithContent.MainHeader>
                  {feature.header}
                </LeftImageWithContent.MainHeader>
                <LeftImageWithContent.SubHeader>
                  <p>{feature.content}</p>
                </LeftImageWithContent.SubHeader>
                <LeftImageWithContent.Link>
                  {feature.link && (
                    <div className="card_link_landing">
                      <a href={feature.link} className="url_manipulation">
                        Learn more{" "}
                        <span className="arrow_svg_link">
                          <ArrowRightICon />
                        </span>
                      </a>
                    </div>
                  )}
                </LeftImageWithContent.Link>
              </LeftImageWithContent>
            );
          })}
          <RequestForm isFooterForm={true} />
        </Layout>
      </Container>
    </>
  );
}
